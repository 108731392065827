<template>
    <div>
        <div v-if="case_show" class="popup" @click.self="handle">
            <div class="popup-content">
                <!-- v-if="list.order_url_image.length > 0" -->
                <el-carousel ref="carouselRef" :interval="3000" height="300" arrow="always" indicator-position="none"
                    :loop="true">
                    <el-carousel-item v-for="(item, index) in list.order_url_image" :key="index">
                        <div class="carousel_img" :style="{ backgroundImage: 'url(' + item + ')' }">
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <!-- v-if="list.order_url_video" -->

                <div class="flex" style="margin: 15px 0;" v-if="list.order_url_video">
                    <p class="video_title">投放视频:</p>
                    <video class="video" controls :src="list.order_url_video" muted></video>
                </div>
                <div class="flex">
                    <p class="video_title">发布链接:</p>
                    <div >
                        <a class="_link" v-for="(link_item, link_index) in list.order_urls" :key="link_index"
                            :href="link_item" target="_blank">{{ link_item }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            Object,
            default: () => { }
        }
    },
    mounted() {
        console.log(this.list);
    },
    data() {
        return {
            case_show: false,
        }
    },
    methods: {
        handle() {
            this.case_show = !this.case_show;
        },

    },
}
</script>

<style lang=scss scoped>
@import '@/scss/poup';

.popup-content {
    // background: none;
}

.video_title {
    width: 100px;
    font-size: 16px;
}

.video {
    max-width: 300px;
    border-radius: 6px;

}

._link {
    display: block;
    color: rgba(16, 140, 221, 1);
    font-size: 14px;
    word-break: break-all;
}

.carousel_img {
    width: 100%;
    height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

}

::v-deep .el-carousel__container {}

::v-deep .popup-content {
    width: 40%;
    padding: 40px;
}

.popup {
    z-index: 9999;
}
</style>