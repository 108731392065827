<template>
    <div>
        <el-dialog class="service_poup" title="联系客服" :append-to-body="true" :visible.sync="poup_show"
            :show-close="false">
            <!-- <p class="workOrder_title">客服电话：83730126/82821214</p> -->
            <img :src="$user_info.custom_info.avatar" alt="" style="max-width: 40%;margin: 0 auto;display: block;">
            <p class="work_order_title">微信扫面上方二维码添加专属客服经理</p>

        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            poup_show: false,
        }
    },
    methods: {
        handle() {
            this.poup_show = !this.poup_show;
        }
    },
}
</script>

<style lang=scss scoped>
.work_order_title {
    font-size:14px;
    color: #333;
    margin:15px 0;
    text-align: center;
}
</style>